import React from 'react'
import { Link } from 'react-router-dom'
import Icons from '../common/Icons'

const Cta = ({
  children,
  className = '',
  header,
  url,
  ...props
}) => {
  return url ? (
    <Link
      to={url}
      {...props}
      className={`${className}  flex py-3 px-6 font-lato group text-light-black text-lg font-semibold text-center gap-1.5 items-center bg-yellow rounded-full relative after:bg-light-black after:h-full after:left-0 after:top-0 after:right-[100%] hover:after:right-0 after:absolute after:duration-300 overflow-hidden z-10 after:-z-10 hover:text-yellow duration-300 transition-all ease-linear after:transition-all after:hover:duration-300 after:hover:transition-all after:hover:ease-linear hover:transition-all hover:ease-linear hover:duration-300`}>
      {children}
      <Icons icon="btn-arrow" className='group-hover:translate-x-2 duration-300 transition-all ease-linear'/>
    </Link>
  ) : (
    <button
      {...props}
      className={`${className} !leading-116 flex sm:py-3 py-2 sm:px-6 px-3 group font-lato text-light-black sm:text-lg text-base font-semibold text-center gap-1.5 items-center bg-yellow rounded-full relative after:bg-light-black after:h-full after:left-0 after:top-0 after:right-[100%] hover:after:right-0 after:absolute after:duration-300 overflow-hidden z-10 after:-z-10 hover:text-yellow duration-300 `}>
      {children}
      <Icons icon="btn-arrow" className='group-hover:translate-x-2 duration-300 transition-all ease-linear'/>
    </button>
  )
}

export default Cta