import HeroPalms from '../components/palms/HeroPalms'
import AboutPalms from '../components/palms/AboutPalms'
import Convenience from '../components/palms/Convenience'
import Location from '../components/palms/Location'
import Gallery from '../components/palms/Gallery'
import OtherProjects from '../components/palms/OtherProjects'
import Newsletter from "../components/common/Newsletter";
import Footer from "../components/common/Footer";
import BookPopup from '../components/bookPopup'
import Popup from '../components/Popup'

const ElegantPalm = () => {
  return (
    <div>
      <HeroPalms />
      <Popup />
      <BookPopup />
      <AboutPalms />
      <Convenience />
      <Location />
      <Gallery />
      <OtherProjects />
      <Newsletter />
      <Footer />
    </div>
  )
}

export default ElegantPalm