import React, { createContext, useState, useContext } from 'react';

const PopupContext = createContext();

export const PopupProvider = ({ children }) => {
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  return (
    <PopupContext.Provider value={{ isPopupVisible, setIsPopupVisible }}>
      {children}
    </PopupContext.Provider>
  );
};


export const usePopup = () => {
  return useContext(PopupContext);
};