import amenitiesMen from '../../assets/images/elegant/webp/amenities-men.webp';
import PrimaryHeading, { SubHeading } from '../custom-ui/CommonHeading';
import Icons from '../common/Icons';
import { AMENITIES_DATA_LIST } from '../../utils/helper';

const Convenience = () => {

  return (
    <div className="max-xl:px-3 max-md:px-5">
      <div className="max-w-[1400px] mx-auto bg-yellow bg-opacity-[8%] py-14 sm:py-16 lg:py-20 xl:py-[104px] max-xl:px-4 rounded-2xl">
        <div className="flex flex-col  justify-center items-center">
          <SubHeading className="max-lg:justify-center uppercase">
            Convenience
          </SubHeading>
          <PrimaryHeading className="md:mt-3 mt-1 max-lg:text-center md:mb-4 mb-3 lg:mb-11">
            <span className="text-black yellow-bottomline max-w-[140px] w-full after:w-full after:!left-[-2px] after:!top-[23px] after:min-w-[140px] after:max-w-[140px]">
              Amenities
            </span>
          </PrimaryHeading>

          <div className="flex flex-row lg:px-14 xl:px-20 flex-wrap mx-[-3px] items-center justify-center lg:justify-between w-full max-custom-lg:max-w-[645px]">
            <div className="lg:w-[25%] max-lg:flex-wrap w-full max-lg:flex-row lg:px-3 flex max-xl:-mx-3 items-center max-lg:px-0 lg:items-end flex-col justify-center lg:justify-end xl:gap-x-0 lg:gap-x-5 md:gap-x-9 max-md:gap-4 md:gap-y-9 lg:gap-y-[34px] lg:mt-0 mt-6">
              {AMENITIES_DATA_LIST.slice(0, 4).map((amenity, index) => (
                <div
                  key={amenity.id}
                  className={`bg-white md:w-[200px] hover:shadow-villaCard duration-300 w-[250px] lg:min-w-[200px] max-custom-xs:min-w-[136px] max-custom-xs:max-w-[136px] xl:min-w-[222px] max-lg:max-w-[200px] max-w-[222px] h-[144px] rounded-md flex flex-col justify-center items-center sm:p-5 p-3 max-sm:h-[133px] shadow-amenities ${
                    (index === 0 || index === 3) && 'lg:-me-16'
                  }`}
                >
                  <Icons icon={amenity.icon} />
                  <p className="text-black font-semibold text-center max-w-[160px] mt-2 md:mt-3.5 font-lato text-sm md:text-base !leading-160">
                    {amenity.text}
                  </p>
                </div>
              ))}
            </div>

            <div className="lg:w-[50%] w-full lg:px-3 xl:-ms-3 max-lg:py-5 flex max-xl:-mx-3 justify-center">
              <img
                width={447}
                height={584}
                className="w-full max-sm:max-w-[200px] max-custom-xs:max-w-full max-xl:max-w-[314px] max-lg:max-w-[336px] max-w-[447px] xl:w-[447px] xl:h-[584px] custom-lg:h-[415px] sm:h-[450px] object-cover rounded-xl"
                src={amenitiesMen}
                alt="thumbMen"
              />
            </div>

            <div className="lg:w-[25%] max-lg:flex-wrap w-full lg:px-3 flex max-xl:-mx-3 justify-center max-lg:px-0 lg:flex-col xl:gap-x-0 lg:gap-x-5 md:gap-x-9 max-md:gap-4 md:gap-y-9 lg:gap-y-[34px] lg:mt-0">
              {AMENITIES_DATA_LIST.slice(4).map((amenity, index) => (
                <div
                  key={amenity.id}
                  className={`bg-white md:w-[200px] hover:shadow-villaCard duration-300 w-[250px] lg:min-w-[200px] max-custom-xs:min-w-[136px] max-custom-xs:max-w-[136px] xl:min-w-[222px] max-lg:max-w-[200px] max-w-[222px] h-[144px] rounded-md flex flex-col justify-center items-center sm:p-5 p-3 max-sm:h-[133px] shadow-amenities ${
                    (index === 0 || index === 3) && 'lg:-ms-16'
                  }`}
                >
                  <Icons icon={amenity.icon} />
                  <p className="text-black font-semibold text-center max-w-[160px] mt-2 md:mt-3.5 font-lato text-sm md:text-base !leading-160">
                    {amenity.text}
                  </p>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Convenience;
