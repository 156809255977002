import { useState, useRef, useEffect } from 'react';
import PrimaryHeading, { SubHeading } from '../custom-ui/CommonHeading';
import { GALLERY_SLIDER_CONTENT, VIDEO_SLIDER_CONTENT, SLIDER_CONTENT_DATA } from '../../utils/helper';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import leftArrow from "../../assets/images/svg/left-arrow-icon.svg";
import rightArrow from "../../assets/images/svg/right-arrow-icon.svg";
import { Navigation } from 'swiper/modules';

const Gallery = () => {
  const [activeTab, setActiveTab] = useState('Images');
  const [activeSlide, setActiveSlide] = useState(null);
  const videoRefs = useRef([]);

  const handleTabSwitch = (tab) => {
    setActiveTab(tab);
  };

  const sliderContent = activeTab === 'Images' ? GALLERY_SLIDER_CONTENT : VIDEO_SLIDER_CONTENT;
  useEffect(() => {
    videoRefs.current.forEach((video, index) => {
      if (video) {
        if (index === activeSlide) {
          video.play();
        } else {
          video.pause();
        }
      }
    });
  }, [activeSlide]);

  return (
    <div className="xl:pt-[140px] max-w-[1440px] mx-auto lg:pt-20 sm:pt-20 pt-14">
      <div className="container mx-auto px-3">
        <SubHeading className="justify-center">GALLERY</SubHeading>
        <PrimaryHeading className="md:mt-3 mt-1 text-center lg:mb-11 md:mb-6 mb-3">
          Our Images & Videos&nbsp;
          <span className="text-yellow yellow-bottomline">Collection</span>
        </PrimaryHeading>

        <div className="mb-[47px] py-2 px-[7.5px] mx-auto max-w-[244px] border border-light-black-100 bg-light-yellow rounded-[50px]">
          <div className="flex gap-3 items-center overflow-auto mx-auto lg:justify-center justify-center w-full">
            {SLIDER_CONTENT_DATA.map((obj, value) => (
              <button
                key={value}
                onClick={() => handleTabSwitch(obj.label)}
                className={`px-[23.5px] py-[10.2px] font-semibold text-lg font-lato leading-120 rounded-[50px] text-nowrap transition-all duration-300 ease-linear ${
                  activeTab === obj.label
                    ? "bg-yellow text-black"
                    : " text-silver-grey"
                }`}
              >
                {obj.label}
              </button>
            ))}
          </div>
        </div>
      </div>

      <div className="relative">
        <Swiper
          slidesPerView={1.2}
          spaceBetween={16}
          loop={true}
          centeredSlides={true}
          className="gallerySwiper"
          modules={[Navigation]}
          navigation={{
            nextEl: ".swiper-btn-next",
            prevEl: ".swiper-btn-prev",
          }}
          onSlideChange={(swiper) => setActiveSlide(swiper.activeIndex)}
          breakpoints={{
            640: {
              slidesPerView: 1.5,
            },
            768: {
              slidesPerView: 1.5,
            },
            1024: {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            1280: {
              slidesPerView: 1.77,
              spaceBetween: 30,
            },
          }}
        >
          {sliderContent.map((obj, index) => (
            <SwiperSlide key={index}>
              {obj.type === "video" ? (
                <div className="relative">
                  <video
                    ref={(el) => (videoRefs.current[index] = el)}
                    width={800}
                    height={483}
                    className="rounded-2xl object-cover max-w-[800px] w-full h-[300px] sm:h-[400px] lg:h-[483px]"
                    controls={true}
                    poster={obj.thumbnail}
                  >
                    <source src={obj.videoUrl} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                </div>
              ) : (
                <img
                  width={800}
                  height={483}
                  className={`rounded-2xl max-w-[800px] w-full h-[300px] sm:h-[400px] lg:h-[483px] object-cover`}
                  src={obj.image}
                  alt={obj.altName}
                />
              )}
            </SwiperSlide>
          ))}
        </Swiper>

        <div className="flex items-center max-lg:mt-6 justify-center z-20 max-lg:gap-3 gap-6 lg:absolute right-[3%] md:right-[5%] lg:right-[11%] custom-2xl:!right-[12%] bottom-[-1%]">
          <div className="swiper-btn-prev lg:w-12 max-lg:w-8 max-lg:h-8 lg:h-12 rounded-full border-[2px] bg-white border-black duration-300 ease-linear hover:bg-yellow cursor-pointer flex justify-center items-center">
            <img
              className="max-lg:w-5 max-lg:h-3"
              src={leftArrow}
              alt="leftArrow"
            />
          </div>
          <div className="swiper-btn-next lg:w-12 max-lg:w-8 max-lg:h-8 lg:h-12 rounded-full border-[2px] bg-white border-black duration-300 ease-linear hover:bg-yellow cursor-pointer flex justify-center items-center">
            <img
              className="max-lg:w-5 max-lg:h-3"
              src={rightArrow}
              alt="rightArrow"
            />
          </div>
        </div>
        <div className="absolute z-10 max-lg:hidden right-0 bottom-[-1%] bg-white rounded-[9999px_0px_0px_9999px] h-[48px] lg:w-[234px] xl:w-[261px] custom-xxl:w-[274px] custom-xl:w-[246px] custom-2xl:w-[287px]"></div>
      </div>
    </div>
  );
};

export default Gallery;
