import { useState } from "react";
import { motion } from "framer-motion";
import { TABS_DATA } from "../utils/helper";
import Icons from "./common/Icons";
import PrimaryHeading, { SubHeading } from "./custom-ui/CommonHeading";
import Cta from "./custom-ui/Cta";
import { usePopup } from "../context/popupContext";

const CompletedProjects = () => {
  const [activeTab, setActiveTab] = useState(TABS_DATA[0].id);
  const { isPopupVisible, setIsPopupVisible } = usePopup();
  const activeData = TABS_DATA.find((tab) => tab.id === activeTab);

  return (
    <div
      id="services"
      className="xl:pt-[104px] xl:pb-[110px] md:py-16 py-8 bg-yellow-gradient xl:mt-[170px] lg:mt-20 sm:mt-16 mt-14 sm:mx-5"
    >
      <div className="max-w-[1180px] px-5 mx-auto">
        <SubHeading className="tracking-[3px] justify-center text-center !mr-0">
          Projects
        </SubHeading>
        <PrimaryHeading className="text-center md:mt-3 mt-1">
          Completed{" "}
          <span className="text-yellow bottom-curveline">Projects</span>
        </PrimaryHeading>
        <div className="md:mb-[53px] my-8 md:mt-[59px] max-w-[836px] p-2 mx-auto border border-light-black-100 bg-light-yellow rounded-[50px]">
          <div className="flex  sm:gap-[21px] gap-[10px] items-center overflow-auto mx-auto lg:justify-center justify-start w-full">
            {TABS_DATA.map((tab) => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`px-6 py-2.5 font-semibold text-lg font-lato leading-120 rounded-[50px] text-nowrap transition-all duration-300 ease-linear hover:bg-yellow hover:text-light-black ${
                  activeTab !== tab.id
                    ? "bg-transparent text-light-brown font-semibold transition-all duration-300 ease-linear"
                    : "bg-yellow text-light-black  hover:bg-gray-300 transition-all duration-300 ease-linear"
                }`}
              >
                {tab.label}
              </button>
            ))}
          </div>
        </div>

        <motion.div
          key={activeTab}
          initial={{ opacity: 0, x: -20 }}
          animate={{ opacity: 1, x: 0 }}
          exit={{ opacity: 0, x: 20 }}
          transition={{ duration: 0.3 }}
          className="flex flex-col md:flex-row md:justify-between max-md:items-center justify-center gap-8"
        >
          <div className="lg:w-8/12 md:w-6/12">
            <img
              src={activeData.image}
              alt={activeData.title}
              className="rounded-[16px] shadow-md max-w-[682px] w-full h-full lg:object-fill object-cover"
            />
          </div>
          <div className="lg:w-4/12 md:w-5/12 w-full flex flex-col items-start justify-center">
            <h2
              className={`text-custom-xs font-gloock leading-130 font-normal text-light-black ${
                activeData.subtitle !== "" && "pb-2"
              }`}
            >
              {activeData.title}
            </h2>
            {activeData.subtitle !== "" && (
              <p className="font-normal text-base text-off-black font-lato">
                {activeData.subtitle}
              </p>
            )}
            <ul className="md:space-y-3 space-y-2 text-gray-700 md:mt-[21px] mt-4">
              <li className="flex items-center justify-start gap-2.5">
                <div className="md:size-[38px] size-[30px] flex items-center justify-center">
                  <Icons icon={activeData.homeIcon} />
                </div>
                <span className="flex items-center justify-center font-normal text-xl leading-normal font-lato text-light-black">
                  Type:-
                  <p className="text-xl font-gloock leading-normal">
                    {activeData.details.type}
                  </p>
                </span>
              </li>
              <li className="flex items-center justify-start gap-2.5">
                <div className="md:size-[38px] size-[30px] flex items-center justify-center">
                  <Icons icon={activeData.villaIcon} />
                </div>
                <span className="flex items-center justify-center font-normal text-xl leading-normal font-lato text-light-black">
                  No. of Villa Plots:-
                  <p className="text-xl font-gloock leading-normal">
                    {activeData.details.villas}
                  </p>
                </span>
              </li>
              <li className="flex items-center justify-start gap-2.5">
                <div className="md:size-[38px] size-[30px] flex items-center justify-center">
                  <Icons icon={activeData.sizeIcon} />
                </div>
                <span className="flex items-center justify-center font-normal text-xl leading-normal font-lato text-light-black">
                  Development Size:-
                  <p className="text-xl font-gloock leading-normal">
                    {activeData.details.size}
                  </p>
                </span>
              </li>
              <li className="flex items-center justify-start gap-2.5">
                <div className="md:size-[38px] size-[30px] flex items-center justify-center">
                  <Icons icon={activeData.locationIcon} />
                </div>
                <span className="flex items-center justify-center font-normal text-xl leading-normal font-lato text-light-black">
                  Location:-
                  <p className="text-xl font-gloock leading-normal">
                    {activeData.details.location}
                  </p>
                </span>
              </li>
            </ul>
            <Cta className="lg:mt-[41px] mt-8" onClick={() => {setIsPopupVisible(true)}}>Explore Now</Cta>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default CompletedProjects;
