import React from "react";
import Icons from "../common/Icons";

const PrimaryHeading = ({
  children,
  reduceBelow,
  maxFontSize,
  minFontSize,
  className = "",
  center,
  right,
  ...props
}) => {
  const minText = minFontSize ? minFontSize : 32;
  const maxText = maxFontSize ? maxFontSize : 52;
  const reduceBefore = reduceBelow ? reduceBelow : 1024;
  const sizeReducer = (100 / reduceBefore) * maxText;
  return (
    <h2
      {...props}
      style={{
        fontSize: `clamp(${minText}px, ${sizeReducer}vw, ${maxText}px)`,
      }}
      className={`${className} ${
        center ? "mx-auto text-center" : right ? "text-right" : " text-left"
      } text-light-black font-gloock leading-130`}
    >
      {children}
    </h2>
  );
};

export default PrimaryHeading;
export const SubHeading = ({
  children,
  reduceBelow,
  maxFontSize,
  minFontSize,
  className = "",
  center,
  right,
  ...props
}) => {
  const minText = minFontSize ? minFontSize : 14;
  const maxText = maxFontSize ? maxFontSize : 18;
  const reduceBefore = reduceBelow ? reduceBelow : 1024;
  const sizeReducer = (100 / reduceBefore) * maxText;
  return (
    <div className={`${className} flex`}>
      <span
        className={`flex items-center gap-2 ${
          center ? "justify-center" : "justify-normal"
        }`}
      >
        <Icons icon="home" />
        <span
          {...props}
          style={{
            fontSize: `clamp(${minText}px, ${sizeReducer}vw, ${maxText}px)`,
          }}
          className={`${className} text-light-black font-lato font-bold leading-160 tracking-[5.12px]`}
        >
          {children}
        </span>
      </span>
    </div>
  );
};
