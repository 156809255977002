import React from 'react'
import thumbMen from "../assets/images/webp/about.webp";
import PrimaryHeading, { SubHeading } from "./custom-ui/CommonHeading";
import CommonParagraph from './CommonParagraph';
import Cta from './custom-ui/Cta';
import { usePopup } from "../context/popupContext";
const About = () => {
  const { isPopupVisible, setIsPopupVisible } = usePopup();
  return (
    <div id="about" className="xl:pt-[140px] lg:pt-20 sm:pt-16 pt-14">
      <div className="container max-w-[1164px] mx-auto md:px-3 px-5">
        <div className="flex lg:flex-row flex-col-reverse flex-wrap mx-[-3px] items-center justify-between">
          <div className="lg:w-[50%] w-full md:px-3 flex justify-center lg:mt-0 mt-6">
            <img
              width={529}
              height={450}
              className="w-full max-w-[529px] sm:h-[450px] object-cover rounded-xl"
              src={thumbMen}
              alt="thumbMen"
            />
          </div>
          <div className="lg:w-[48%] lg:pt-8 pt-0 w-full md:px-3 max-lg:flex justify-center items-center flex-col">
            <SubHeading className="max-lg:justify-center">About Us</SubHeading>
            <PrimaryHeading className="md:mt-[10px] mt-1 max-lg:text-center md:mb-4 mb-3">
              Crafting{" "}
              <span className="text-yellow about-bottomline">Your Vision</span>{" "}
              into Reality
            </PrimaryHeading>
            <CommonParagraph className="md:pb-10 pb-7 max-lg:text-center">
              Visionary Venture, a renowned name in Bangalore’s real estate
              sector, has been dedicated to delivering exceptional services to
              our community since our inception in 2015. Guided by a commitment
              to excellence, we have surpassed numerous milestones on our
              journey to success, continuously pushing the boundaries of
              innovation and service.
            </CommonParagraph>
            <Cta className="w-fit" onClick={() => {setIsPopupVisible(true)}}>Read More</Cta>
          </div>
        </div>
      </div>
    </div>
  );
}

export default About