import React from "react";
import PrimaryHeading, { SubHeading } from "../custom-ui/CommonHeading";
import { OTHER_PROJECT_DATA_LIST } from "../../utils/helper";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const OtherProjects = () => {
  var settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 2.4,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "16px",
    autoplay: true,
    autoplaySpeed: 4000,
    cssEase: "linear",
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          centerPadding: "40px",
        },
      },
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 2.3,
          centerPadding: "50px",
          centerMode: false,
        },
      },
      {
        breakpoint: 760,
        settings: {
          slidesToShow: 1.7,
          centerPadding: "80px",
          centerMode: true,
        },
      },
      {
        breakpoint: 730,
        settings: {
          slidesToShow: 1.7,
          centerPadding: "50px",
          centerMode: true,
        },
      },
      {
        breakpoint: 660,
        settings: {
          slidesToShow: 1.7,
          centerPadding: "20px",
          centerMode: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          centerMode: false,
        },
      },
    ],
  };

  return (
    <div
      id="project"
      className="xl:pt-[170px] lg:pt-20 sm:pt-16 pt-14 lg:pb-0 sm:pb-14 pb-14 overflow-x-clip"
    >
      <div className="container xl:max-w-[1164px] max-md:max-w-full mx-auto lg:px-3 px-5">
        <SubHeading className="justify-center">Projects</SubHeading>
        <PrimaryHeading className="sm:mt-3 mt-1 lg:pb-[73px] md:pb-8 pb-5 text-center">
          Other <span className="text-yellow palmProjects-curveline">Projects</span>
        </PrimaryHeading>
        <div className="hidden lg:flex flex-wrap gap-6 justify-center">
          {OTHER_PROJECT_DATA_LIST.map((object, index) => (
            <div
              key={index}
              className="bg-gray shadow-light-white group transition-all ease-linear duration-300"
            >
              <img
                width={364}
                height={229}
                className="lg:w-[364px] max-w-[364px] h-[229px] object-cover w-full"
                src={object.image}
                alt="projects-image"
              />
              <div className="p-[25px]">
                <p className="font-gloock font-normal sm:text-custom-xsm text-2xl leading-132 text-light-black text-center pb-2">
                  {object.title}
                </p>
                <p className="font-lato font-normal text-base leading-normal text-off-black text-center lg:opacity-0 opacity-100 group-hover:opacity-100 transition-all ease-linear duration-300">
                  {object.location}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className="lg:hidden">
          <Slider {...settings}>
            {OTHER_PROJECT_DATA_LIST.map((object, index) => (
              <div
                key={index}
                className="bg-gray shadow-light-white group transition-all ease-linear duration-300 custom-lg:max-w-[364px] sm:max-w-[300px] !w-full"
              >
                <img
                  width={364}
                  height={229}
                  className="lg:w-[364px] custom-lg:max-w-[364px] max-w-[300px] h-[229px] object-cover w-full"
                  src={object.image}
                  alt="projects-image"
                />
                <div className="p-[25px]">
                  <p className="font-gloock font-normal sm:text-custom-xsm text-2xl leading-132 text-light-black text-center pb-2">
                    {object.title}
                  </p>
                  <p className="font-lato font-normal text-base leading-normal text-off-black text-center">
                    {object.location}
                  </p>
                </div>
              </div>
            ))}
          </Slider>
        </div>
      </div>
    </div>
  );
};

export default OtherProjects;
