import React, { useState, useEffect } from "react";
import PrimaryHeading from "../custom-ui/CommonHeading";
import CommonParagraph from "../CommonParagraph";
import Cta from "../custom-ui/Cta";

const Newsletter = () => {
  const [email, setEmail] = useState("");
  const [error, setError] = useState("");
  const [isPopupVisible, setIsPopupVisible] = useState(false);

  const handleChange = (e) => {
    setEmail(e.target.value);
    setError("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(email)) {
      setError("Please enter a valid email address.");
      return;
    }

    console.log("Form submitted:", email);
    setIsPopupVisible(true);
    setEmail("");
  };

  useEffect(() => {
    if (isPopupVisible) {
      const timer = setTimeout(() => {
        setIsPopupVisible(false);
      }, 1000);
      return () => clearTimeout(timer);
    }
  }, [isPopupVisible]);

  return (
    <div
      id="newsletter"
      className="relative z-10 xl:pt-[146px] lg:pt-20 sm:pt-16 pt-14"
    >
      <div className="xl:container xl:max-w-[1164px] px-5 mx-auto">
        <div className="bg-cta-box bg-full rounded-2xl lg:h-[350px] lg:py-[66px] sm:py-[40px] p-[20px] max-w-[1140px] mx-auto mb-[-148px]">
          <PrimaryHeading className="mb-4 text-center">
            Subscribe to Newsletter
          </PrimaryHeading>
          <CommonParagraph className="max-w-[555px] text-center mx-auto lg:pb-10 sm:pb-8 pb-4">
            Sign up for our newsletter to stay up-to-date on the latest
            promotions, discounts, and new feature releases.
          </CommonParagraph>
          <form
            onSubmit={handleSubmit}
            className="bg-gray max-w-[415px] rounded-[100px] flex md:p-[2px_2px_2px_15px] p-[4px_3px_4px_10px] mx-auto lg:h-[52px]"
          >
            <input
              type="text"
              placeholder="Enter your mail"
              value={email}
              onChange={handleChange}
              className="sm:text-lg text-base !leading-116 font-normal text-light-black bg-transparent w-full outline-none placeholder-light-black"
              required
            />
            <Cta
              type="submit"
              className="sm:max-w-[152px] max-w-[118px] w-full"
            >
              Subscribe
            </Cta>
          </form>
          {error && <p className="text-red-500 text-center mt-2">{error}</p>}
        </div>
      </div>

      {/* Success Popup */}
      {isPopupVisible && (
        <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-6 rounded-lg shadow-lg max-w-sm mx-auto text-center">
            <h3 className="text-xl font-semibold mb-4 font-lato">
              Subscription Successful
            </h3>
            <CommonParagraph className="mb-6">
              Thank you for subscribing to our newsletter!
            </CommonParagraph>
          </div>
        </div>
      )}
    </div>
  );
};

export default Newsletter;
