import { useState } from "react";
import navLogo from "../assets/images/svg/nav-logo.svg";
import { NAV_LINK_LIST } from "../utils/helper";
import Cta from "./custom-ui/Cta";
import { usePopup } from "../context/popupContext";

const NavBar = () => {
  const [show, setShow] = useState(true);
  const { isPopupVisible, setIsPopupVisible } = usePopup();
  const [dropdownVisible, setDropdownVisible] = useState(false);

  if (show === false) {
    document.body.classList.add("max-lg:overflow-hidden");
  } else {
    document.body.classList.remove("max-lg:overflow-hidden");
  }

  return (
    <nav className="bg-[#FEFDFC] py-[5px]">
      <div className="container mx-auto  max-w-[1180px] px-5">
        <div className="flex justify-between items-center">
          <a className="flex items-center" href="/">
            <img
              src={navLogo}
              alt="logo"
              className="max-w-[136.88px] max-h-[90px]"
            />{" "}
          </a>
          <ul
            className={`flex gap-6 items-center mobileView ${
              show ? "right-[100%] " : "right-0 max-md:min-h-screen"
            }`}
          >
              {NAV_LINK_LIST.map((data, i) => (
              <li
                onClick={() => {
                  if (data.title !== "Projects") {
                    setShow(!show);
                  }
                }}
                key={i}
                className="hover:text-yellow after:left-[0] after:bg-yellow after:right-[200%] after:-bottom-0.5 hover:after:right-0 transition-all duration-300 after:duration-300 capitalize after:absolute relative after:h-[3px] font-lato font-normal text-light-black text-lg !leading-150"
                onMouseEnter={() => data.title === "Projects" && setDropdownVisible(true)}
                onMouseLeave={() => data.title === "Projects" && setDropdownVisible(false)}
              >
                <a href={data.url === "projects" ? "#" : data.url}>{data.title}</a>
                {data.title === "Projects" && dropdownVisible && (
                  <ul
                    className="absolute z-50 bg-white shadow-lg mt-1 rounded-md min-w-[200px] py-3 border-t-4 border-yellow"
                    onMouseEnter={() => setDropdownVisible(true)}
                    onMouseLeave={() => setDropdownVisible(false)}
                  >
                    <li className="px-4 py-2 hover:bg-gray-200">
                      <a href="/elegant-palm">Elegant Palm</a>
                    </li>
                
                  </ul>
                )}
              </li>
            ))}
            <li className="md:hidden">
              {" "}
              <Cta onClick={() => {setIsPopupVisible(true)}}>Book A Site Visit</Cta>
            </li>
          </ul>

          <div className="flex gap-3 items-center">
            <span onClick={() => {setIsPopupVisible(true)}}><Cta className="max-md:hidden">Book A Site Visit</Cta></span>
            <label className="md:hidden" onClick={() => setShow(!show)}>
              {show ? (
                <div className=" z-40 relative">
                  <span className="flex bg-light-black h-1 rounded-3xl duration-300 w-6"></span>
                  <span className="flex bg-light-black h-1 rounded-3xl duration-300 w-6 mt-1"></span>
                  <span className="flex bg-light-black h-1 rounded-3xl duration-300 w-6 mt-1"></span>
                </div>
              ) : (
                <div className="z-40 relative">
                  <span className="flex bg-light-black absolute rounded-3xl -left-7 duration-300 top-1 rotate-45 h-[3px] w-6"></span>
                  <span className="flex bg-light-black absolute rounded-3xl -left-7 duration-300 -rotate-45 h-[3px] w-6 mt-1"></span>
                </div>
              )}
            </label>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default NavBar;
