import { PLAM_CARD_LIST } from '../../utils/helper';
import Icons from '../common/Icons';
import NavBar from '../NavBar';

const HeroPalms = () => {

    return (
        <div id="home" className="flex flex-col">
            <NavBar />
            <div className='px-5'>
                <div className='bg-hero max-w-[1400px] mx-auto h-full lg:min-h-[480px] pb-14 sm:pb-16 pt-14 lg:py-20 sm:pt-16 xl:py-[107px] xl:max-h-[480px] xl:z-30 rounded-2xl relative xl:ps-0 sm:ps-5 ps-0'>
                    <div className='container max-w-[1180px] flex flex-col justify-center items-center mx-auto sm:px-5 px-4'>
                        <p className='text-center  text-light-black font-lato font-bold leading-160 flex items-start sm:items-center text-base md:text-lg tracking-[5.12px] gap-2'><span className='max-custom-xs:size-5 max-custom-xs:-me-2'><Icons icon="home" />
                        </span>Welcome to Visionary Venture</p>
                        <h1 className='xl:text-custom-md lg:!text-custom-5xl md:text-5xl text-[40px] font-gloock text-center !leading-122 text-black max-w-[885px] mt-2.5 sm:mb-5 mb-3'>
                            Visionary Elegant Palm <span className=' text-yellow heroPalms-bottomline'><br className=' max-sm:hidden lg:hidden' />Project</span>
                        </h1>
                        <p className='font-lato text-lg font-medium !leading-normal text-light-black text-center mb-4 lg:mt-7 sm:mb-10'>
                            10mins Drive to Sadahalli Metro Station
                        </p>
                        <div className='flex flex-wrap gap-4 lg:gap-6 gap-9 max-sm:gap-5 justify-center items-center'>
                            {PLAM_CARD_LIST.map((card, index) => (
                                <div
                                    key={index}
                                    className='xl:px-7 py-5 xl:py-[26px] bg-white duration-300 hover:border-opacity-100 flex flex-col max-lg:justify-center items-center rounded-lg border-yellow border-opacity-20 shadow-villaCard border-[1px] h-[144px] lg:h-[150px] xl:h-[174px] w-[260px] max-sm:w-[133px] max-sm:h-[130px] max-sm:px-2 sm:w-[200px] xl:w-[267px]'
                                >
                                    <Icons icon={card.icon} />
                                    <p className='text-base sm:text-xl xl:text-2xl font-normal text-center mt-1 !leading-120 font-gloock max-w-[182px]'>
                                        {card.text}
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default HeroPalms;
