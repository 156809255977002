import React, { useState, useEffect, useRef } from "react";
import Cta from "./custom-ui/Cta";
import popimg from "../assets/images/webp/popup-img.webp";
import { IoMdCloseCircle } from "react-icons/io";
import { usePopup } from "../context/popupContext";

const BookPopup = () => {
    const { isPopupVisible, setIsPopupVisible } = usePopup();
  const [formValues, setFormValues] = useState({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    time: "",
    date: ""
  });
  const [errors, setErrors] = useState({});
  const popupRef = useRef(null);
  const [successMessage, setSuccessMessage] = useState("");
  
  const handleChange = (e) => {
    const { id, value } = e.target;
    const updatedValue = id === "phone" ? value.replace(/[^0-9]/g, "") : value;
    setFormValues({ ...formValues, [id]: updatedValue });
    setErrors({ ...errors, [id]: "" });
  };
  const validate = () => {
    const newErrors = {};
    if (!formValues.firstName.trim()) {
      newErrors.firstName = "First name is required";
    }
    if (!formValues.lastName.trim()) {
      newErrors.lastName = "Last name is required";
    }
    if (!formValues.email.trim()) {
      newErrors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(formValues.email)) {
      newErrors.email = "Email is invalid";
    }
    if (!formValues.phone.trim()) {
      newErrors.phone = "Phone number is required";
    } else if (!/^\d{10,15}$/.test(formValues.phone)) {
      newErrors.phone = "Phone number must be between 10 to 15 digits";
    }

    if (!formValues.time.trim()) {
      newErrors.time = "Time is required";
    }
    if (!formValues.date.trim()) {
      newErrors.date = "Date is required";
    }
    
    return newErrors;
  };
  const handleSubmit = (e) => {
    e.preventDefault();
   
    const validationErrors = validate();
  
    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      setSuccessMessage("");
    } else {
      fetch("https://hooks.zapier.com/hooks/catch/20873108/2irmkne/", {
        method: "POST",
        body: JSON.stringify(formValues),
      })
      .then(response => response.json())
      .then(data => {
        setSuccessMessage("Your enquiry has been submitted successfully!");
        
        setFormValues({
          firstName: "",
          lastName: "",
          email: "",
          phone: "",
          time: "",
          date: ""
        });
        setErrors({});
        
        setTimeout(() => {
          setIsPopupVisible(false);
        }, 4000);
      })
      .catch((error) => {
        console.error("Error:", error);
        setSuccessMessage("");
        setErrors({formError: "Error submitting form"});
      });
    }
  };

 


  if (!isPopupVisible) return null;

  return (
    <div className="fixed inset-0 z-50 flex items-center justify-center p-5">
      <div className="absolute inset-0 bg-black bg-opacity-50 z-40 p-5"></div>
      <div
        ref={popupRef}
        className="relative z-50 max-w-[1140px] max-h-screen overflow-y-auto p-5 sm:p-10 md:p-[66px] bg-white mx-auto rounded-lg max-sm:rounded-xl shadow-lg max-sm:w-full"
      >
        <div className="flex lg:gap-[45px] md:gap-7 gap-6 max-lg:flex-col-reverse items-center">
          <div className="lg:max-w-[616px] lg:w-full sm:w-[80%] w-full mx-auto">
          <button
          onClick={() => setIsPopupVisible(false)}
          className="absolute top-4 right-4 text-gray-500 hover:text-gray-700"
        >
         <IoMdCloseCircle className="h-8 w-8 hover:rotate-90 transition-all ease-linear duration-300 md:text-black text-white" />
        </button>
            <h2 className="text-custom-xs font-gloock font-normal text-light-black leading-125 lg:mb-10 md:mb-8 mb-5">
              Enquiry Form
            </h2>
            <form onSubmit={handleSubmit}>
              <div className="flex max-sm:flex-col gap-4 mb-4">
                <div className="sm:w-1/2 w-full">
                  <label
                    htmlFor="firstName"
                    className="font-gloock text-base leading-120 font-normal mb-1.5"
                  >
                    First Name
                  </label>
                  <input
                    type="text"
                    id="firstName"
                    value={formValues.firstName}
                    onChange={handleChange}
                    className={`md:h-12 sm:h-10 h-8 w-full border border-solid p-2 focus:!outline-none rounded ${
                      errors.firstName
                        ? "border-red-500"
                        : "border-light-black border-opacity-10"
                    }`}
                  />
                  {errors.firstName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.firstName}
                    </p>
                  )}
                </div>
                <div className="sm:w-1/2 w-full">
                  <label
                    htmlFor="lastName"
                    className="font-gloock text-base leading-120 font-normal mb-1.5"
                  >
                    Last Name
                  </label>
                  <input
                    type="text"
                    id="lastName"
                    value={formValues.lastName}
                    onChange={handleChange}
                    className={`md:h-12 sm:h-10 h-8 w-full border border-solid p-2 focus:!outline-none rounded ${
                      errors.lastName
                        ? "border-red-500"
                        : "border-light-black border-opacity-10"
                    }`}
                  />
                  {errors.lastName && (
                    <p className="text-red-500 text-sm mt-1">
                      {errors.lastName}
                    </p>
                  )}
                </div>
              </div>
              <div className="flex max-sm:flex-col gap-4 mb-4">
                <div className="sm:w-1/2 w-full">
                  <label
                    htmlFor="email"
                    className="font-gloock text-base leading-120 font-normal mb-1.5"
                  >
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={formValues.email}
                    onChange={handleChange}
                    className={`md:h-12 sm:h-10 h-8 w-full border border-solid p-2 focus:!outline-none rounded ${
                      errors.email
                        ? "border-red-500"
                        : "border-light-black border-opacity-10"
                    }`}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm mt-1">{errors.email}</p>
                  )}
                </div>
                <div className="sm:w-1/2 w-full">
                  <label
                    htmlFor="phone"
                    className="font-gloock text-base leading-120 font-normal mb-1.5"
                  >
                    Phone Number
                  </label>
                  <input
                    type="number"
                    id="phone"
                    value={formValues.phone}
                    onChange={handleChange}
                    className={`md:h-12 sm:h-10 h-8 w-full border border-solid p-2 focus:!outline-none rounded ${
                      errors.phone
                        ? "border-red-500"
                        : "border-light-black border-opacity-10"
                    }`}
                  />
                  {errors.phone && (
                    <p className="text-red-500 text-sm mt-1">{errors.phone}</p>
                  )}
                </div>
              </div>
         
                <div className="flex max-sm:flex-col gap-4 mb-4">
                  <div class="sm:w-1/2 w-full">
                    <label
                      htmlFor="date"
                      className="font-gloock text-base leading-120 font-normal mb-1.5"
                    >
                    Date
                    </label>
                    <input
                      type="date"
                      id="date"
                      value={formValues.date}
                      onChange={handleChange}
                      className={`md:h-12 sm:h-10 h-8 w-full border border-solid p-2 focus:!outline-none rounded ${
                        errors.date
                          ? "border-red-500"
                          : "border-light-black border-opacity-10"
                      }`}
                    />
                    {errors.date && (
                      <p className="text-red-500 text-sm mt-1">{errors.date}</p>
                    )}
                  </div>
                  <div class="sm:w-1/2 w-full">
                    <label
                      htmlFor="time"
                      className="font-gloock text-base leading-120 font-normal mb-1.5"
                    >
                      Time
                    </label>
                    <input
                      id="time"
                      type="time"
                      value={formValues.time}
                      onChange={handleChange}
                      className={`md:h-12 sm:h-10 h-8 w-full border border-solid p-2 focus:!outline-none rounded ${
                        errors.time
                          ? "border-red-500"
                          : "border-light-black border-opacity-10"
                      }`}
                    />
                    {errors.date && (
                      <p className="text-red-500 text-sm mt-1">{errors.time}</p>
                    )}
                  </div>
                </div>
          
              <div className="mt-4">
                <Cta type="submit" className="w-full justify-center">
                  Submit
                </Cta>
              </div>
            </form>
            {successMessage && (
              <p className="text-green-500 text-sm mt-2 font-semibold">{successMessage}</p>
            )}
            {errors.formError && (
              <p className="text-red-500 text-sm mt-2 font-semibold">{errors.formError.date}</p>
            )}
            
          </div>
          <img
            src={popimg}
            alt="popimg"
            className="lg:max-w-[347px] md:max-h-[466px] sm:max-h-[350px] max-h-[300px] w-full object-cover mx-auto rounded-lg sm:rounded-xl lg:rounded-2xl"
          />
        </div>
      </div>
    </div>
  );
};

export default BookPopup;
