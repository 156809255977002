import {
  ABOUT_LINK,
  CONTACT,
  PROJECT_LINK,
  TESTIMONIALS,
  SERVICES,
  HOME,
} from "./constant";
import elegantPalms from "../assets/images/svg/elegant-palms.svg";
import visionaryCelesta from "../assets/images/svg/visionary-celesta.svg";
import sereneSquare from "../assets/images/svg/serene-square.svg";
import daffodils from "../assets/images/svg/daffodils.svg";
import enclave from "../assets/images/png/tab-imag-enclave.png";
import residency from "../assets/images/png/tab-imag-residency.png";
import square from "../assets/images/png/tab-imag-square.png";
import arcade from "../assets/images/png/tab-imag-arcade.png";
import tabDaffodils from "../assets/images/png/tab-imag-daffodils.png";
import sliderImg1 from "../assets/images/webp/slider-img1.webp";
import sliderImg2 from "../assets/images/webp/slider-img2.webp";
import sliderImg3 from "../assets/images/webp/slider-img3.webp";
import sliderImg4 from "../assets/images/webp/slider-img4.webp";
import sliderImg5 from "../assets/images/webp/slider-img6.webp";
import sliderImg6 from "../assets/images/webp/slider-img5.webp";
import ongoingProjects from "../assets/images/webp/ongoing-projects.webp";
import ongoingProjects2 from "../assets/images/webp/ongoing-project-2.webp";
import ongoingProjects3 from "../assets/images/webp/ongoing-project-3.webp";
import ongoingProjects4 from "../assets/images/webp/ongoing-project-4.webp";
import daffodilsProject from "../assets/images/elegant/webp/daffodils-project.webp";
import celestaProject from "../assets/images/elegant/webp/visionary-project.webp";
import sereneSquareProject from "../assets/images/elegant/webp/serene-square.webp";
import gallerySlideOne from "../assets/images/webp/gallery-slide-one.webp";
import gallerySlideTwo from "../assets/images/webp/gallery-slide-two.webp";
import gallerySlideThree from "../assets/images/webp/gallery-slide-three.webp";
import demoVideo from "../assets/videos/demo.mp4";
import demoVideoTwo from "../assets/videos/demo-two.mp4";
import demoVideoThree from "../assets/videos/demo-three.mp4";


export const NAV_LINK_LIST = [
  {
    title: "Home",
    url: "/",
  },
  {
    title: "About Us",
    url: ABOUT_LINK,
  },
  {
    title: "Projects",
    url: "#",
  },
  {
    title: "Contact Us",
    url: "/contact-us",
  },
];
export const AMENITIES_DATA_LIST = [
  { id: 1, icon: "security", text: "Gated Community with 24 hrs Security" },
  { id: 2, icon: "entrance", text: "Arched Entrance" },
  { id: 3, icon: "light", text: "LED Street Lights" },
  { id: 4, icon: "stp", text: "S.T.P" },
  { id: 5, icon: "wiring", text: "Underground Wiring & Electric Connection" },
  { id: 6, icon: "water", text: "Individual Water and Drainage Connections" },
  { id: 7, icon: "children", text: "Childrens Play Area" },
  { id: 8, icon: "park", text: "Park" },
];

export const PLAM_CARD_LIST = [
  {
    icon: "personal-villa",
    text: "Personalised Villas",
  },
  {
    icon: "plot-villa",
    text: "52 Villa Plots",
  },
  {
    icon: "acer-land",
    text: "3.5 Acres of Land",
  },
  {
    icon: "premium",
    text: "Premium Residential Plots",
  },
];

// ======================================FREQUENTYL======================================
export const ACCORDIONDATA = [
  {
    index: 0,
    icon: elegantPalms,
    title: "Project Highlights",
  },
  {
    index: 1,
    icon: visionaryCelesta,
    title: "Amenities",
  },
  {
    index: 2,
    icon: sereneSquare,
    title: "Prime location",
  },
  {
    index: 3,
    icon: daffodils,
    title: "Drone view",
  },
  {
    index: 4,
    icon: daffodils,
    title: "Walkthrough",
  },
  {
    index: 5,
    icon: daffodils,
    title: "BROCHURE",
  },
];
// ======================================GALLERY-SLIDER======================================
export const GALLERY_SLIDER_CONTENT = [
  {
    image: gallerySlideOne,
    altName: gallerySlideOne,
  },
  {
    image: gallerySlideTwo,
    altName: gallerySlideTwo,
  },
  {
    image: gallerySlideThree,
    altName: gallerySlideThree,
  },
  {
    image: gallerySlideOne,
    altName: gallerySlideOne,
  },
  {
    image: gallerySlideTwo,
    altName: gallerySlideTwo,
  },
  {
    image: gallerySlideThree,
    altName: gallerySlideThree,
  },
];
export const VIDEO_SLIDER_CONTENT = [
  {
    type: "video",
    videoUrl: demoVideo,
    altName: "Video 1",
  },
  {
    type: "video",
    videoUrl: demoVideoTwo,
    altName: "Video 2",
  },
  {
    type: "video",
    videoUrl: demoVideoThree,
    altName: "Video 3",
  },
  {
    type: "video",
    videoUrl: demoVideo,
    altName: "Video 1",
  },
  {
    type: "video",
    videoUrl: demoVideoTwo,
    altName: "Video 2",
  },
  {
    type: "video",
    videoUrl: demoVideoThree,
    altName: "Video 3",
  },
];

export const ACCORDIONIMAGE = [
  ongoingProjects,
  ongoingProjects2,
  ongoingProjects3,
  ongoingProjects4,
];
export const TABS_DATA = [
  {
    id: "arcade",
    label: "ARCADE",
    image: arcade,
    homeIcon: "home",
    villaIcon: "plot-home",
    sizeIcon: "laptop-info",
    locationIcon: "location",
    title: "ARCADE",
    subtitle: "Where Every Moment Is Bliss in Devanahalli.",
    details: {
      type: "Commercial",
      villas: "154",
      size: "9.5 Acres",
      location: "Devanahalli",
    },
  },
  {
    id: "enclave",
    label: "ENCLAVE",
    image: enclave,
    homeIcon: "home",
    villaIcon: "plot-home",
    sizeIcon: "laptop-info",
    locationIcon: "location",
    title: "ENCLAVE",
    subtitle: "Your Gateway to Tranquility in Hoskote",
    details: {
      type: "Plot",
      villas: "368",
      size: "32 Acres",
      location: "Hoskote",
    },
  },
  {
    id: "residency",
    label: "RESIDENCY",
    image: residency,
    homeIcon: "home",
    villaIcon: "plot-home",
    sizeIcon: "laptop-info",
    locationIcon: "location",
    title: "RESIDENCY",
    subtitle: "Where Dreams Take Root in K.R. Puram",
    details: {
      type: "Plot",
      villas: "18",
      size: "1.5 Acres",
      location: "K R Puram",
    },
  },
  {
    id: "daffodils",
    label: "DAFFODILS",
    image: tabDaffodils,
    homeIcon: "home",
    villaIcon: "plot-home",
    sizeIcon: "laptop-info",
    locationIcon: "location",
    subtitle: "",
    title: "DAFFODILS",
    details: {
      type: "Plot",
      villas: "70",
      size: "5.5 Acres",
      location: "Kundana Circle",
    },
  },
  {
    id: "serene-square",
    label: "SERENE SQUARE",
    image: square,
    homeIcon: "home",
    villaIcon: "plot-home",
    sizeIcon: "laptop-info",
    locationIcon: "location",
    title: "SERENE SQUARE",
    subtitle: "Where Serenity Meets Convenience in Devanahalli",
    details: {
      type: "Plot",
      villas: "72",
      size: "3 Acres",
      location: "Kiadb Road",
    },
  },
 
];
export const FOOTER_ITEMS = [
  {
    titleName: "Quick Links",
    items: [
      { name: "Home", path: HOME },
      { name: "About Us", path: ABOUT_LINK },
      { name: "Projects", path: PROJECT_LINK },
      { name: "Services", path: SERVICES },
      { name: "Contact Us", path: CONTACT },
      { name: "Testimonials", path: TESTIMONIALS },
    ],
  },
  {
    titleName: "Services",
    items: [
      { name: "Privacy Policy", path: "/privacy-policy" },
      { name: "Terms of Services", path: "/terms-of-services" },
    ],
  },
];

export const SOCIAL_ICON = [
  {
    icon: "instagramIcon",
    link: "https://www.instagram.com/visionaryventureco/",
  },
  {
    icon: "facebookIcon",
    link: "https://www.facebook.com/VisionaryVenture",
  },
  // {
  //   icon: "twitterIcon",
  //   link: "https://x.com/?lang=en",
  // },
];
export const CONTACT_INFO = [
  {
    icon: "phoneIcon",
    label: "9741153555",
    link: "tel:9741153555",
  },
  {
    icon: "mailIcon",
    label: "reachus@visionaryventure.co.in",
    link: "mailto:reachus@visionaryventure.co.in",
  },
];
export const OURTESTMONIALS = [
  {
    img: sliderImg1,
    title: "Aarav S.",
    subtitle: "A Seamless Experience",
    content:
      "Working with Visionary Venture was an absolute pleasure. From start to finish, they made the entire process of buying our villa plot incredibly straightforward and stress-free. Their expert team was with us every step of the way, ensuring we had all the information and guidance we needed to make the right decision. Thanks to their efficient and personalized approach, what could have been a complex experience was made simple and enjoyable. ",
  },
  {
    img: sliderImg2,
    title: "Darlene Robertson",
    subtitle: "A Trusted Partner",
    content:
      "From the beginning, Visionary Venture’s team made every part of the process clear and easy. Their deep knowledge of the real estate market, combined with their commitment to understanding our goals, helped us confidently choose the best investment option. Their attention to detail, transparency, and expert guidance made the experience enjoyable and stress-free. Thanks to them, we made a smart investment that we're excited about. ",
  },
  {
    img: sliderImg3,
    title: "Rajesh P.",
    subtitle: "Beyond Expectations",
    content:
      "Their professionalism and dedication made our investment journey smooth and rewarding. From the very first interaction, the team provided clear communication, expert guidance, and unmatched support. They understood our needs and ensured that every step of the process was seamless and efficient. The attention to detail in their projects and their commitment to delivering quality truly set them apart. Visionary Venture is not just a real estate provider.",
  },
  {
    img: sliderImg4,
    title: "Neha R.",
    subtitle: "Exceptional Service",
    content:
      "They took the time to listen to our needs, guiding us with patience and expertise to find the perfect plot for our dream home. Their approach was personalized, ensuring that every aspect of the process was tailored to our preferences and requirements. From site visits to paperwork, everything was handled smoothly and professionally. The quality of the plots and the attention to detail in the development reassured us that we had made the right choice.",
  },
  {
    img: sliderImg5,
    title: "Jenny Wilson",
    subtitle: "Exceptional Service",
    content:
      "Visionary Venture made the entire process effortless. Their team understood our needs and delivered a seamless experience.From the initial consultation to finalizing the purchase, Visionary Venture’s team ensured every step was smooth and hassle-free. They took the time to listen to our specific needs and preferences, providing expert guidance throughout. Their professionalism and attention to detail made the entire journey stress-free, and we always felt informed and supported. ",
  },
  {
    img: sliderImg6,
    title: "Ralph Edwards",
    subtitle: "Highly Recommended",
    content:
      "From the very first consultation to the final steps of securing our plot, the team at Visionary Venture was by our side every step of the way. Their deep knowledge of the market and attentive approach made us feel confident in every decision. We truly appreciated their dedication to ensuring we found the best location for our new home. The process was smooth, transparent, and tailored to our needs. We highly recommend Visionary Venture for anyone looking to invest in real estate!",
  },
];
// ================== OTHER-PROJECTS ==================
export const OTHER_PROJECT_DATA_LIST = [
  {
    image: daffodilsProject,
    title: "Daffodils",
    location: "Behind Baratiya City, BBMP Limits",
  },
  {
    image: celestaProject,
    title: "Visionary Celesta",
    location: "Behind Baratiya City, BBMP Limits",
  },
  {
    image: sereneSquareProject,
    title: "Serene Square",
    location: "Behind Baratiya City, BBMP Limits",
  },
];

//===================== Roadmap data===========================
export const LOCATION_DATA_LIST = [
  { name: "MG Road", time: "1 hour 10 minutes" },
  { name: "Hebbal", time: "30 minutes" },
  { name: "Whitefield ", time: "1 hour 20 minutes" },
  { name: "International Airport", time: "21 minutes" },
  { name: "Manyata Tech Park", time: "39 minutes" },
  { name: "ITPL", time: "1 hour 25 minutes" },
];

export const SLIDER_CONTENT_DATA = [
  {
    label: "Images",
  },
  {
    label: "Videos",
  },
];
