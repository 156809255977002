import React, { useRef, useCallback } from "react";
import PrimaryHeading, { SubHeading } from "./custom-ui/CommonHeading";
import testmonials from "../assets/images/webp/testmonials-img.webp";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css/navigation";
import { Pagination, Navigation, EffectFade, Autoplay } from "swiper/modules";
import { OURTESTMONIALS } from "../utils/helper";
import "swiper/swiper-bundle.css";
import Icons from "./common/Icons";

const OurTestmonials = () => {
  const sliderRef = useRef(null);

  const handlePrev = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slidePrev();
  }, []);

  const handleNext = useCallback(() => {
    if (!sliderRef.current) return;
    sliderRef.current.swiper.slideNext();
  }, []);

  return (
    <div id="testimonials" className="xl:pt-[140px] lg:pt-20 sm:pt-16 pt-14">
      <div className="container xl:max-w-[1140px] mx-auto md:px-3 px-5">
        <SubHeading className="justify-center">Our Testimonials</SubHeading>
        <PrimaryHeading className="md:mt-[10px] mt-2 text-center mx-auto lg:max-w-[656px] max-w-[630px]">
          Client Experiences with{" "}
          <span className="text-yellow visionary-bottom-line">Visionary</span>{" "}
          Venture
        </PrimaryHeading>
        <div className="pt-6 md:pt-10 lg:pt-12 xl:pt-20 flex flex-row flex-wrap items-center">
          <div className="lg:w-[51%] w-full relative flex flex-col justify-center items-center">
            <Swiper
              ref={sliderRef}
              spaceBetween={24}
              slidesPerView={1}
              loop={true}
              autoplay={{
                delay: 2000,
                disableOnInteraction: false,
              }}
              breakpoints={{
                0: { slidesPerView: 1, spaceBetween: 16 },
                768: { slidesPerView: 1, spaceBetween: 24 },
              }}
              navigation={{ nextEl: ".nextbtn", prevEl: ".prevbtn" }}
              modules={[Pagination, Navigation, EffectFade, Autoplay]}
              className="!rounded-2xl w-full max-w-[558px]"
            >
              {OURTESTMONIALS.map((obj, i) => (
                <SwiperSlide className="!h-auto" key={i}>
                  <div className="w-full py-6 md:py-9 px-4 md:px-6 border border-solid border-light-yellow-200 bg-light-yellow-100 rounded-2xl flex flex-col items-center h-[487px] max-md:h-[370px] max-sm:h-[390px]">
                    <img
                      src={obj.img}
                      alt="testimonial-img"
                      className="w-[90px] lg:w-[150px] sm:w-[120px] rounded-full mb-4 md:mb-6"
                    />
                    <p className="font-normal font-gloock text-xl md:text-2xl text-center leading-[1.4] text-light-black mb-1">
                      {obj.title}
                    </p>
                    <p className="font-semibold font-lato text-light-black leading-normal text-base md:text-lg text-center mb-2">
                      {obj.subtitle}
                    </p>
                    <p className="font-lato font-normal text-center text-sm md:text-base text-off-black leading-normal max-w-[510px] max-xl:line-clamp-[7]">
                      {obj.content}
                    </p>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>

            <div className="flex sm:gap-10 gap-8 pt-4 max-xl:pt-9">
              <button
                className="prevbtn xl:absolute -translate-y-1/2 top-1/2 lg:left-[-40px] left-[34px] hover:opacity-55 transition-opacity ease-linear duration-300"
                onClick={handlePrev}
              >
                <Icons icon="sliderPrevArrow" />
              </button>
              <button
                className="nextbtn xl:absolute top-1/2 -translate-y-1/2 lg:right-[-40px] right-[34px] hover:opacity-55 transition-opacity ease-linear duration-300"
                onClick={handleNext}
              >
                <Icons icon="sliderNextArrow" />
              </button>
            </div>
          </div>

          <div className="lg:w-[49%] w-full flex lg:justify-end justify-center lg:pt-0 pt-6">
            <img
              src={testmonials}
              alt="testimonials"
              className="w-full max-w-[400px] md:max-w-[475px] max-h-[360px] md:max-h-[419px] rounded-xl object-cover"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurTestmonials;
