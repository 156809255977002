import PrimaryHeading, { SubHeading } from "../custom-ui/CommonHeading";
import CommonParagraph from "../CommonParagraph";
import Cta from "../custom-ui/Cta";
import garden from "../../assets/images/elegant/webp/garden.webp";
import gardenVilla from "../../assets/images/elegant/webp/garden-villa.webp";
import layer from "../../assets/images/elegant/webp/plam-layer.webp";
import Icons from "../common/Icons";
import { usePopup } from "../../context/popupContext";

const AboutPalms = () => {
  const {  setIsPopupVisible } = usePopup();
  return (
    <div id="about"  className="relative xl:z-20">
      <div className="absolute start-0 z-0 end-0 top-[-19%] max-xl:hidden mx-auto">
        <img
          width={274}
          height={335}
          className="w-full h-[400px] rotate-180"
          src={layer}
          alt="thumbMen"
        />
      </div>
      <div className="container xl:max-w-[1164px] mx-auto px-5 py-14 sm:py-16 lg:py-20 xl:pb-[157px] xl:pt-[271px]">
        <div className="flex lg:flex-row flex-col-reverse flex-wrap mx-[-3px] items-center xl:items-start justify-between">
          <div className="lg:w-[50%] w-full md:px-3 flex max-xl:-mx-3 justify-center lg:mt-0 mt-6">
            <div className="flex max-custom-xs:flex-col gap-6 lg:pe-6 xl:pe-0">
              {" "}
              <img
                width={274}
                className="w-full   max-sm:max-w-[200px] max-custom-xs:max-h-[400px] max-custom-xs:max-w-full max-xl:max-w-[245px] max-w-[274px] xl:min-w-[274px] sm:h-[470px] lg:h-[590px] object-cover rounded-xl"
                src={garden}
                alt="thumbMen"
              />
              <div className="flex gap-y-6 flex-col">
                <img
                  width={274}
                  height={335}
                  className="w-full   max-sm:max-w-[200px] max-custom-xs:max-w-full max-xl:max-w-[245px] max-w-[274px] xl:w-[274px] sm:h-[260px] lg:h-[335px] object-cover rounded-xl"
                  src={gardenVilla}
                  alt="thumbMen"
                />
                <div className="bg-yellow bg-opacity-10 max-custom-xs:max-w-full  max-sm:max-w-[200px] max-md:h-[180px]  max-xl:max-w-[245px]  custom-xs:w-[274px] h-[185px] lg:h-[230px] flex justify-center items-center my-auto rounded-2xl duration-300 hover:border-opacity-100 border-yellow border-opacity-20 shadow-villaCard border-[1px]">
                  <p className="font-normal font-gloock text-3xl lg:text-4xl !leading-130  text-black text-center lg:max-w-[150px] ">
                    {" "}
                    <span className="text-yellow text-2xl md:!text-[40px]">
                      100%{" "}
                    </span>{" "}
                    <br /> Customize Villa
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="lg:w-[48%]  pt-0 w-full xl:ps-1 lg:ps-4  md:px-3 max-lg:flex justify-center items-center flex-col">
            <SubHeading className="max-lg:justify-center">
              About Elegant Palm
            </SubHeading>
            <PrimaryHeading className="md:mt-[10px] mt-1 max-lg:text-center md:mb-4 mb-3">
              Visinory Elegant{" "}
              <span className="text-yellow plam-curveline max-w-[140px] w-full after:w-full after:min-w-[140px] after:max-w-[140px]">
                {" "}
                Palm
              </span>{" "}
            </PrimaryHeading>
            <CommonParagraph className="md:pb-10 pb-7 pt-2 xl:pt-0 md:px-10 lg:px-0 max-lg:text-center">
              Welcome to Visionary Venture, a 3.5 acres gated community of niche
              52 plots that gives you the freedom to live as you desire. An
              ideal opportunity to invest and savour the experience that comes
              with a relaxed lifestyle, assorted plots in a green canopy
              surrounded by Eite institutes and residential townships such as
              Padukone Dravid Sports Excellence, Stone Hill International, AMITY
              University, Manyata earthsong, Arvind GreatLands, Century Greens,
              Adarsh Savana to name a few.
            </CommonParagraph>
            <Cta className="w-fit" onClick={() => {setIsPopupVisible(true)}}>Read More</Cta>
            <div className="flex items-center mt-2 max-sm:-ms-9 md:mt-6 lg:mt-4 xl:mt-[26px] max-lg:-ms-7 xl:h-[174px]">
              <div className="flex flex-col xl:w-[198px]  justify-center items-center">
                <Icons icon="acer-land" />
                <p className="text-base md:text-lg !leading-120 text-black font-gloock font-normal max-sm:max-w-[160px] max-w-[220px] mt-1 text-center">
                  Total 3.5 Acres of Land Total Units
                </p>
              </div>
              <div>
                <div className="max-sm:pe-6 max-sm:ps-1 px-10">
                  {" "}
                  <Icons icon="yellow-lines" className="h-[174px] " />
                </div>
              </div>
              <div className="flex flex-col xl:w-[198px]  justify-center items-center">
                <Icons className="!size-[60px]" icon="home-plot" />
                <p className="text-base md:text-lg !leading-120 text-black font-gloock font-normal max-w-[220px] mt-1 text-center">
                  52 Villa Plots
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutPalms;
