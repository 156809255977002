import { FOOTER_ITEMS, SOCIAL_ICON, CONTACT_INFO } from "../../utils/helper";
import React from "react";
import footerLogo from "../../assets/images/svg/footer-logo.svg";
import Icons from "./Icons";
import CommonParagraph from "../CommonParagraph";
import whatsApp from "../../assets/images/svg/whatsApp.svg";

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div id="contact" className="bg-light-black pt-[190px] relative">
     <a href="https://wa.me/9743429661/?text=hi" target="blank">
        <img
          width={60}
          height={60}
          className="sm:size-11 size-10 object-cover rounded-full fixed sm:bottom-7 xl:bottom-[52px] xl:right-[52px] bottom-[10px] right-[10px] hover:scale-110 transition-all ease-linear duration-300 z-10"
          src={whatsApp}
          alt="whatsApp"
        />
      </a>
      <div className="container max-w-[1164px] mx-auto md:px-3 px-5">
        <div className="flex flex-wrap flex-row justify-between">
          <div className="w-full xl:w-[34%] lg:w-[31%] md:px-3">
            <a href="/" className="!w-fit">
              <img
                width={170}
                height={90}
                className="pb-4 max-w-[170px] h-[90px] object-cover"
                src={footerLogo}
                alt="footerLogo"
              />
            </a>
            <CommonParagraph className="lg:max-w-[369px] max-w-[517px] pb-5 !text-light-gray">
              At Visionary Venture, we provide tailored real estate solutions
              with a focus on quality and customer satisfaction. Let us guide
              you through your next investment or dream home journey.
            </CommonParagraph>
            <div className="flex items-center w-full gap-[14px]">
              {SOCIAL_ICON.map((value, index) => (
                <a
                  key={index}
                  href={value.link}
                  target="_blank"
                  className="size-[33px] bg-transparent rounded-[32px] border-[0.84px] border-solid border-yellow flex flex-col items-center justify-center duration-300 ease-linear hover:scale-110"
                >
                  <Icons icon={value.icon} />
                </a>
              ))}
            </div>
          </div>
          <div className="w-full xl:w-[60%] lg:w-[66%] min-[780px]:w-[69%] lg:px-3 mt-12 lg:mt-0">
            <div className="flex flex-row flex-wrap justify-between">
              {FOOTER_ITEMS.map((value, index) => (
                <ul
                  key={index}
                  className="flex flex-col lg:w-[24%] sm:w-[24%] w-[38%] xl:px-0 md:px-3"
                >
                  <li className="font-normal sm:text-2xl text-xl !leading-137 text-gray font-gloock lg:mb-4 mb-3 text-nowrap">
                    {value.titleName}
                  </li>
                  {value.items.map((data, index) => (
                    <li key={index} className="pb-[14px]">
                      <a
                        href={data.path}
                        className="font-lato font-normal text-base !leading-normal text-light-gray relative after:absolute after:h-[1px] after:w-0 hover:after:w-full after:bottom-0 after:left-0 after:bg-white after:rounded after:transition-all after:ease-linear after:duration-300 "
                      >
                        {data.name}
                      </a>
                    </li>
                  ))}
                </ul>
              ))}
              <ul className="flex flex-col  xl:w-5/12 lg:w-[43%] md:w-6/12 w-9/12 max-md:mt-10">
                <li className="font-normal sm:text-2xl text-xl !leading-137 text-gray font-gloock lg:mb-4 mb-3">
                  Contact us
                </li>
                {CONTACT_INFO.map((item, index) => (
                  <li key={index} className="flex items-top gap-2 pb-[14px]">
                    <Icons icon={item.icon} className="text-yellow" />
                    <a
                      href={item.link}
                      className="font-normal text-base !leading-normal text-light-gray relative after:absolute after:h-[1px] after:w-0 hover:after:w-full after:bottom-0 after:left-0 after:bg-white after:rounded after:transition-all after:ease-linear after:duration-300 font-lato max-w-[250px]"
                    >
                      {item.label}
                    </a>
                  </li>
                ))}
                <li className="flex gap-2 items-top">
                  <Icons icon="locationIcon" />
                  <a
                    target="blank"
                    href="https://maps.app.goo.gl/WDyvHXMC7ffoF1AAA"
                    className="font-normal text-base !leading-normal text-light-gray relative after:absolute after:h-[1px] after:w-0 hover:after:w-full after:bottom-0 after:left-0 after:bg-white after:rounded after:transition-all after:ease-linear after:duration-300 font-lato lg:max-w-[250px] max-sm:max-w-[310px]"
                  >
                    Visionary Venture
                    <br />
                    #788, 3rd Floor, A Block, Opposite Ganesha Temple,
                    Sahakarnagar, Bangalore -560092
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
      <div className="border-t border-solid border-light-white mt-[48px]">
        <p className="font-lato font-normal text-base leading-normal text-light-gray py-[14px] text-center">
          Copyright {currentYear} | Visionary Venture | All Rights Reserved
        </p>
      </div>
    </div>
  );
};

export default Footer;
